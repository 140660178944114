<template>
  <b-card>
    <div id="container-xl" v-if="service">
      <div id="left">
        <div>
          <b-img src="@/assets/images/operator.jpg" fluid class="pr-4"></b-img>
          <b-row class="mt-4">
            <b-col cols="3" >
              <b-img fluid src="@/assets/images/operator.jpg" />
            </b-col>
            <b-col cols="3">
              <b-img fluid src="@/assets/images/operator.jpg" />
            </b-col>
            <b-col cols="3">
              <b-img fluid src="@/assets/images/operator.jpg" />
            </b-col>
          </b-row>
        </div>
      </div>
      <div id="middle">
        <h2>{{ service.name }}</h2>
        <i class="fas fa-map-marker-alt"></i> <b>Dubai</b><br /><br />
        <h5>Service Details</h5>
        <b-row>
        <b-col cols="6"><p><b>Registration:</b> {{ service.registration }}</p></b-col>
        <b-col cols="6"><p><b>Experience:</b> {{ service.experience_years }}</p></b-col>
        <b-col cols="6"><p><b>Fitness Score:</b> {{ service.fitness_score }}</p></b-col>
        <b-col cols="12"><h5>Pricing Details</h5></b-col>
        <b-col cols="6"><p><b>Rental Price:</b> {{ service.rate_price }}/{{ service.rate_unit }}</p></b-col>
        <b-col cols="6"><p><b>Monthly Discount:</b> {{ service.monthly_discount }}</p></b-col>
        <b-col cols="6"><p><b>Yearly Discount:</b> {{ service.yearly_discount }}</p></b-col>
        <b-col cols="6"><p><b>Start Time:</b> {{ service.initial_time }}</p></b-col>
        <b-col cols="12"><h5>Product Details</h5></b-col>
        <b-col cols="6"><p><b>Fitness certificate:</b> {{ service.fitness_certificate }}</p></b-col>
        <b-col cols="6"><p><b>Specification:</b> {{ service.specification }}</p></b-col>
        <b-col cols="6"><p><b>Terms:</b> {{ service.terms }}</p></b-col>
        <b-col cols="12"><h5>Product Detail Description</h5></b-col>
        <b-col cols="12"><p><b>Details:</b> {{ service.comments }}</p></b-col>
        </b-row>
      </div>
      <div id="right">
      
      <div class="text-center bg-light">
        
        <div class="d-flex flex-column p-4 mt-4">
          <h3>Select Dates</h3>
          
                <b-form-group>
                <b-form-radio-group
                    v-model="availability"
                    :options="avalOptions"
                ></b-form-radio-group>
                </b-form-group>
            
          <!--Single Day-->
          <b-datepicker v-if="availability === 'single'" v-model="startDate"></b-datepicker><br />
          

          <!--Multiple Days-->
          <div v-if="availability === 'multiple'">
            <label class="fw-medium">Start Date</label>
            <b-form-datepicker v-model="startDate"></b-form-datepicker><br />

            <label class="fw-medium">End Date</label>
            <b-form-datepicker v-model="form.end_date"></b-form-datepicker><br>
          </div>
        </div>
        <div v-if="isAuthenticated" class="d-flex flex-column px-4 mt-4">
          <h3>{{ service.rate_price }}/{{ service.rate_unit }}</h3>
          <b-button v-if="dateValid" variant="warning"
             @click="triggerPopup('quote')">Request Booking</b-button
          >
          <b-button v-else variant="warning" disabled>Request Booking</b-button
          >
        </div>
        <div class="d-flex flex-column p-4 mt-4">
          <h4 class="text-center">Not Sure!<br> you can order our inspection service by a qualified inspector to be sure about this!</h4>
          <b-button variant="primary" @click="triggerPopup('quote')">Get It Inspected</b-button
          >
        </div>
      </div>

      </div>

    <!--<div id="container-xl">
      <div id="left2" class="text-center bg-light">
        
          <br>
          <h6>Submit a Review</h6><br>
          <div style="margin-left: 45px">
          <b-input placeholder="Your Name" style="width:150px" type="text" class=" shadow"></b-input><br>
          <b-input placeholder="Write your review.." type="text" style="width: 150px" class="shadow"></b-input><br><br>
        </div>
      </div>
      <div id="middle2" style="margin-left: 5px" class=" text-center bg-light">
        <h5>Customer Reviews</h5>
        <div class="bg-grey text-center">
          Review
          <br>
          ..<br>
          ..<br>
          ..<br>
          ..<br>
          ..<br>
        </div>
        <b-pagination style="margin-left:2.7in"></b-pagination>
      </div>
    </div>-->

    <div class="popup border-popup" v-if="showPopupQuote==true">
		<div class="popup-inner text-center">
      <b-button-close @click="triggerPopup('close')"></b-button-close>
      <h3>Send Offer</h3><br>
      <b-form inline>
			<div>

        <b-input type="text" style="width:450px" v-model="form.offered_price" placeholder="Offered Price"></b-input><br><br>
        <b-input type="text" style="width:450px" v-model="form.booking_comments" placeholder="I am interested in buying the vehicle. Please Review my Offer?"></b-input><br>
        
        <br><b-button style="width:120px" variant="primary" @click="submitBooking()">Submit</b-button><br><br>
        <a href="#" style="color:green"><b><u>Login or Create a free account to send direct offers</u></b></a>
      </div>
      </b-form>
		</div>
	</div>
    </div>
  </b-card>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
export default {
  data: () => ({
    mainProps: {
          center: false,
          fluidGrow: false,
          blank: true,
          blankColor: '#bbb',
          width: 400,
          height: 400,
          class: ''
    },
    showSingle: false,
    showMultiple: false,
    showPopupQuote: false,
    service: null,
    form: {
      order_type: "Sale",
      service_id: null,
      user_id: null,
      offered_price: null,
      start_date: null,
      end_date: null,
      start_time: "09:00:00",
      end_time: "18:00:00",
      payment_status: 0,
      payment_method: "Default",
      booking_date: null,
      booking_comments: null,
      booking_status: 0
    },
    startDate: null,
    availability: null,
    dateValid: false,
    avalOptions: [
      { text: "Single", value: "single"},
      { text: "Multiple", value: "multiple"}
    ]
  }),
  watch: {
    startDate(val){
      this.checkDate(val.start_date);
      this.form.start_date = val;
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      authEmail: (state) => state.auth.authEmail,
      users: (state) => state.auth.users,
      authId: (state) => state.auth.authId
    })
  },
  mounted() {
    this.getService({id: this.$route.params.id}).then(res =>{
      this.service = res[0];
    });
  },
  methods: {
    ...mapActions(["getAvailability", "getService", "saveBooking"]),
    submitBooking(){
      this.form.service_id = this.service.id;
      this.form.user_id = this.authId;
      this.form.order_type = "rent";
      this.form.booking_date = moment().format("YYYY-MM-DD hh:mm:ss");
      
      this.saveBooking(this.form).then(res => {
        if(res && 'status' in res && res.status >= 400){
          this.$bvToast.toast(res.data, {
            title: "Something Went Wrong",
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.triggerPopup("close");
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
        }
      })
    },
    checkDate(){
      this.getAvailability({id: this.service.calendar_template_id, date: this.startDate}).then((res) => {
        if(res === "Date is valid"){
          this.dateValid = true
        }
        else{
          this.dateValid = false
        }
      })
    },
    toggle(label) {
      if (label == "single") {
        this.showSingle = true;
        this.showMultiple = false;
      }
      if (label == "multiple") {
        this.showSingle = false;
        this.showMultiple = true;
      }
    },
    triggerPopup(label2){
      if(label2=="quote"){
        this.showPopupQuote=true;
      }
      if(label2=="close"){
        this.showPopupQuote = false;
      }
    }
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
#container {
  height: 100%;
  width: 100%;
  font-size: 0;
}
#left,
#middle,
#right {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
}
#left {
  width: 45%;
}
#middle {
  width: 34.59%;
}
#right {
  width: 20%;
}
#left2,
#middle2,
#right2 {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
}
#left2 {
  width: 20%;
}
#middle2 {
  width: 55%;
}
.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.2);
	
	display: flex;
	align-items: center;
	justify-content: center;
}
	.popup-inner {
		background: #FFF;
		padding: 32px;
	}
</style>